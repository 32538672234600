
import { PontoApoio } from "@/services/PontoApoioService";
import { defineComponent, PropType, ref, watch } from "vue";

interface Responsavel {
  nome: string;
  documento: string;
}

export default defineComponent({
  emits: ["update:visible", "update"],
  props: {
    responsaveis: {
      type: Array as PropType<Responsavel[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    return {
      add() {
        props.responsaveis.push({
          nome: "",
          documento: "",
        });
      },
      remove(a: Responsavel) {
        props.responsaveis.splice(props.responsaveis.indexOf(a), 1);
      },
    };
  },
});
