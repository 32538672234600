
import {
  getInstance,
  PontoApoio,
  initialPontoApoio,
} from "@/services/PontoApoioService";
import { defineComponent, PropType, watch } from "vue";
import { useCadastro } from "@/composables/crud";
import { useBuscaCep } from "@/composables/cep";
import { ufs } from "@/utils/endereco";
import Responsaveis from "./Responsaveis.vue";

export default defineComponent({
  components: { Responsaveis },
  emits: ["update:visible", "cadastrar"],
  props: {
    id: [Number, String] as PropType<number>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { data, reset, salvar, loading, saving, load } =
      useCadastro<PontoApoio>(getInstance(), initialPontoApoio);

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );
    function close() {
      if (!loading.value) {
        emit("update:visible", false);
      }
    }

    const cep = useBuscaCep((resp) => {
      data.logradouro = resp.logradouro;
      data.complemento = resp.complemento;
      data.bairro = resp.bairro;
      data.cidade = resp.localidade;
      data.uf = resp.uf;
    });

    return {
      cep,
      ufs,
      saving,
      loading,
      data,
      reset,

      close,
      async salvar() {
        if (await salvar(props.id)) {
          emit("cadastrar");
          close();
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && loading.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
