<template>
  <div v-if="!responsaveis?.length">Sem responsáveis cadastrados!</div>
  <div
    v-else
    v-for="(r, i) in responsaveis"
    :key="i"
    class="p-d-flex p-pb-2 p-mb-2 p-ai-center anexo"
  >
    <div style="flex: 1" class="p-mr-2">
      <p-inputtext v-model="r.nome" placeholder="Nome" style="width: 100%" />
    </div>
    <div style="flex: 1" class="p-mr-2">
      <p-inputtext
        v-model="r.documento"
        placeholder="Documento"
        v-input-mask="['999.999.999-99', '99.999.999/9999-99']"
      />
    </div>
    <div class="p-text-right">
      <p-button icon="pi pi-minus" class="p-button-danger" @click="remove(r)" />
    </div>
  </div>
  <div class="p-mt-1 p-text-right">
    <p-button class="p-button-secondary" icon="pi pi-plus" @click="add" />
  </div>
</template>

<script lang="ts">
import { PontoApoio } from "@/services/PontoApoioService";
import { defineComponent, PropType, ref, watch } from "vue";

interface Responsavel {
  nome: string;
  documento: string;
}

export default defineComponent({
  emits: ["update:visible", "update"],
  props: {
    responsaveis: {
      type: Array as PropType<Responsavel[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    return {
      add() {
        props.responsaveis.push({
          nome: "",
          documento: "",
        });
      },
      remove(a: Responsavel) {
        props.responsaveis.splice(props.responsaveis.indexOf(a), 1);
      },
    };
  },
});
</script>
