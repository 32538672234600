<template>
  <div class="p-grid card">
    <p-toolbar class="p-mb-4 p-col-12">
      <template #left>
        <h5 class="p-m-0">Pontos de Apoio</h5>
      </template>

      <template #right>
        <span class="p-d-inline-flex p-mr-2">
          <p-inputtext
            v-model="search.q"
            @keypress.enter="consultar"
            placeholder="Pesquisar..."
          />
          <p-button icon="pi pi-search" @click="consultar" />
        </span>
        <p-button
          @click="dialogCadastro = true"
          icon="pi pi-plus"
          label="Novo"
        />
      </template>
    </p-toolbar>

    <p-datatable
      class="p-col-12"
      data-key="id"
      :value="data"
      :paginator="true"
      :rows="search.first"
      :lazy="true"
      :totalRecords="paginatorInfo.total"
      :loading="loading"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[25, 50, 200, 500]"
      currentPageReportTemplate="{first} - {last} de {totalRecords}"
      stripedRows
    >
      <template #empty>
        <div class="p-text-center p-mt-5 p-mb-5">Nenhum registro encontrado!</div>
      </template>

      <p-column field="e.id" header="ID" :sortable="true">
        <template #body="{ data }">{{ data.id }}</template>
      </p-column>
      <p-column field="e.documento" header="CNPJ">
        <template #body="{ data }">{{ data.documento }}</template>
      </p-column>
      <p-column field="e.nome" header="Razão Social">
        <template #body="{ data }">{{ data.nome }}</template>
      </p-column>
      <p-column field="e.apelido" header="Fantasia">
        <template #body="{ data }">{{ data.apelido }}</template>
      </p-column>
      <p-column field="e.cidade" header="Cidade">
        <template #body="{ data }">{{ data.cidade }}</template>
      </p-column>
      <p-column field="e.uf" header="UF">
        <template #body="{ data }">{{ data.uf }}</template>
      </p-column>
      <p-column
        field="e.user_id"
        header="Afiliado Vinculado"
        :sortable="true"
        style="min-width: 300px"
      >
        <template #body="{ data }">
          <div class="p-d-flex p-ai-center">
            <x-avatar
              class="p-mr-2"
              style="
                min-width: 30px;
                width: 30px;
                min-height: 30px;
                height: 30px;
              "
              :user="data.user"
            />
            <a style="cursor: pointer" @click="userView.show(data.user.id)">
              {{
                data.user.name
              }}
            </a>
          </div>
        </template>
      </p-column>
      <p-column header="Status">
        <template #body="{ data }">
          <b @click="operacoes.show(data)" style="cursor: pointer">
            {{
              data.status
            }}
          </b>
        </template>
      </p-column>
      <p-column>
        <template #body="slotProps">
          <div class="p-d-flex p-jc-end">
            <p-button
              class="p-button-danger p-mr-2"
              icon="pi pi-trash"
              @click="excluir(slotProps.data)"
            />
            <p-button icon="pi pi-pencil" @click="editar(slotProps.data)" />
          </div>
        </template>
      </p-column>
    </p-datatable>
  </div>

  <x-user-view
    v-model:visible="userView.visible"
    :title="userView.title"
    :id="userView.id"
  ></x-user-view>
  <Cadastro
    v-model:visible="dialogCadastro"
    @cadastrar="consultar"
    :id="selecionado?.id"
  />
  <Operacoes
    v-model:visible="operacoes.visible"
    :pa="operacoes.pa"
    @update="operacaoUpdated"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { getInstance, PontoApoio } from "@/services/PontoApoioService";
import Cadastro from "./Cadastro.vue";
import { useConsulta, useExcluir } from "@/composables/crud";
import { formatDate } from "@/utils/format";
import { useShowUser } from "@/components/UserView.vue";
import Operacoes from "./Operacoes.vue";

interface Operacoes {
  visible: boolean;
  pa: PontoApoio;
  show: (pa: PontoApoio) => void;
}

function useOperacoes() {
  const operacoes = reactive<Operacoes>({
    visible: false,
    pa: null!,
    show(pa: PontoApoio) {
      this.visible = true;
      this.pa = pa;
    },
  });
  watch(
    () => operacoes.visible,
    (v) => {
      if (!v) {
        operacoes.pa = null!;
      }
    }
  );
  return operacoes;
}

export default defineComponent({
  components: { Cadastro, Operacoes },
  setup() {
    const userView = useShowUser();
    const { data, loading, search, paginatorInfo, consultar } =
      useConsulta<PontoApoio>(getInstance());
    const { confirmarExcluir } = useExcluir(getInstance());
    const dialogCadastro = ref(false);
    const selecionado = ref<PontoApoio>();

    search.sortField = "e.id";
    search.sortOrder = "ASC";

    watch(dialogCadastro, (v) => !v && (selecionado.value = undefined));

    onMounted(() => consultar());

    const operacoes = useOperacoes();

    return {
      operacoes,
      userView,
      formatDate,
      dialogCadastro,
      data,
      selecionado,
      paginatorInfo,

      loading,
      search,
      consultar,
      onPage(event: any) {
        search.first = event.rows;
        search.page = event.page + 1;
      },
      onSort(event: any) {
        search.sortField = event.sortField;
        search.sortOrder = event.sortOrder > 0 ? "ASC" : "DESC";
      },

      editar(p: PontoApoio) {
        selecionado.value = p;
        dialogCadastro.value = true;
      },

      async excluir(p: PontoApoio) {
        (await confirmarExcluir(p.id)) && consultar();
      },

      operacaoUpdated() {
        consultar();
      },
    };
  },
});
</script>
