
import { PontoApoio, getInstance } from "@/services/PontoApoioService";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { defineComponent, PropType, ref, watch } from "vue";

export default defineComponent({
  emits: ["update:visible", "update"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    pa: Object as PropType<PontoApoio>,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const confirm = useConfirm();

    function close() {
      if (saving.value) {
        return;
      }
      emit("update:visible", false);
    }

    async function confirmar(
      message = "Tem certeza que deseja excluir?"
    ): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        confirm.require({
          message,
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          async accept() {
            resolve(true);
          },
          reject() {
            resolve(false);
          },
        });
      });
    }

    const saving = ref(false);
    async function changeStatus(status: string) {
      if (!props.pa) {
        return;
      }
      if (!(await confirmar("Tem certeza?"))) {
        return;
      }
      saving.value = true;
      try {
        await getInstance().changeStatus(props.pa.id, status);
        toast.add({
          severity: "success",
          summary: "Status alterado",
          life: 3000,
        });
        emit("update");
        saving.value = false;
        close();
      } finally {
        saving.value = false;
      }
    }

    return {
      saving,
      close,
      changeStatus,
      updateDialogVisible(v: boolean) {
        if (!v && saving.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
