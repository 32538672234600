<template>
  <p-dialog
    header="Operações de Ponto Apoio"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 500px"
  >
    <div v-if="saving" class="p-text-center">
      <p-progress-spinner />
    </div>

    <template v-if="pa && !saving">
      <div class="p-mb-4">Ponto de Apoio #{{ pa.id }} - {{ pa.status }}</div>

      <template v-if="pa.status === 'pre_cadastro'">
        <p-button
          label="Em Analise"
          @click="changeStatus('em_analise')"
          class="p-mr-2"
        />
      </template>
      <template v-if="pa.status === 'em_analise'">
        <p-button
          label="Aprovado"
          @click="changeStatus('aprovado')"
          class="p-mr-2"
        />
        <p-button
          label="Indeferido"
          @click="changeStatus('indeferido')"
          class="p-mr-2"
        />
      </template>
      <template v-if="pa.status === 'ativo'">
        <p-button
          label="Inativo"
          @click="changeStatus('inativo')"
          class="p-mr-2"
        />
      </template>
      <template v-if="pa.status === 'indeferido'">
        <p-button
          label="Em Analise"
          @click="changeStatus('em_analise')"
          class="p-mr-2"
        />
      </template>
      <template v-if="pa.status === 'inativo'">
        <p-button label="Ativo" @click="changeStatus('ativo')" class="p-mr-2" />
      </template>
      <template v-if="pa.status === 'aprovado'">
        <p-button
          label="Em Analise"
          @click="changeStatus('em_analise')"
          class="p-mr-2"
        />
      </template>

      <!-- <div>
        <p-button
          label="Inativo"
          @click="changeStatus('inativo')"
          class="p-mr-2"
        />
        <p-button label="Ativo" @click="changeStatus('ativo')" class="p-mr-2" />
        <p-button
          label="Em analise"
          @click="changeStatus('em_analise')"
          class="p-mr-2"
        />
        <p-button
          label="Indeferido"
          @click="changeStatus('indeferido')"
          class="p-mr-2"
        />
        <p-button
          label="Aprovado"
          @click="changeStatus('aprovado')"
          class="p-mr-2"
        />
      </div>-->
    </template>
  </p-dialog>
</template>

<script lang="ts">
import { PontoApoio, getInstance } from "@/services/PontoApoioService";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { defineComponent, PropType, ref, watch } from "vue";

export default defineComponent({
  emits: ["update:visible", "update"],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    pa: Object as PropType<PontoApoio>,
  },
  setup(props, { emit }) {
    const toast = useToast();
    const confirm = useConfirm();

    function close() {
      if (saving.value) {
        return;
      }
      emit("update:visible", false);
    }

    async function confirmar(
      message = "Tem certeza que deseja excluir?"
    ): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        confirm.require({
          message,
          header: "Confirmação",
          icon: "pi pi-exclamation-triangle",
          async accept() {
            resolve(true);
          },
          reject() {
            resolve(false);
          },
        });
      });
    }

    const saving = ref(false);
    async function changeStatus(status: string) {
      if (!props.pa) {
        return;
      }
      if (!(await confirmar("Tem certeza?"))) {
        return;
      }
      saving.value = true;
      try {
        await getInstance().changeStatus(props.pa.id, status);
        toast.add({
          severity: "success",
          summary: "Status alterado",
          life: 3000,
        });
        emit("update");
        saving.value = false;
        close();
      } finally {
        saving.value = false;
      }
    }

    return {
      saving,
      close,
      changeStatus,
      updateDialogVisible(v: boolean) {
        if (!v && saving.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>
