<template>
  <p-dialog
    :header="`${id ? 'Editar' : 'Cadastrar'} Ponto de Apoio`"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 1024px"
  >
    <form class="p-grid form" @submit.prevent="salvar">
      <div class="p-col-12 p-md-6 p-fluid">
        <div class="p-field">
          <label>Afiliado Vinculado:</label>
          <x-foreign-user v-model="data.user" required />
        </div>
        <div class="p-field">
          <label>Indicador:</label>
          <x-foreign-user v-model="data.indicador" />
        </div>

        <div class="p-field">
          <label>CNPJ:</label>
          <p-inputtext
            v-model="data.documento"
            v-input-mask="['99.999.999/9999-99']"
          />
        </div>
        <div class="p-field">
          <label>Inscrição Estadual:</label>
          <p-inputtext v-model="data.rgInsc" />
        </div>
        <div class="p-field">
          <label>Razão Social:</label>
          <p-inputtext v-model="data.nome" required />
        </div>
        <div class="p-field">
          <label>Identificação (Nome Fantasia):</label>
          <p-inputtext v-model="data.apelido" required />
        </div>

        <div class="p-field">
          <label>E-mail:</label>
          <p-inputtext type="email" v-model="data.email" required />
        </div>
        <div class="p-field">
          <label>Telefone:</label>
          <p-inputtext
            v-model="data.telefone"
            required
            v-input-mask="['(99) 9999-9999', '(99) 99999-9999']"
          />
        </div>
        <div class="p-field">
          <label>Whatsapp:</label>
          <p-inputtext
            v-model="data.whatsapp"
            required
            v-input-mask="['(99) 9999-9999', '(99) 99999-9999']"
          />
        </div>
        <div class="p-card p-p-2">
          <Responsaveis :responsaveis="data.responsaveis" />
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-fluid">
        <div class="p-field">
          <label style="display: block">
            CEP
            <i v-if="cep.loading.value" class="pi pi-spin pi-spinner"></i>
          </label>
          <InputMask
            v-model="data.cep"
            required
            mask="99999-999"
            @blur="cep.consultar(data.cep)"
            style="width: 100px"
          />
        </div>
        <div class="p-field">
          <label>Logradouro (Rua,Avenida,Estrada,Rodovia,Etc)</label>
          <p-inputtext v-model="data.logradouro" required />
        </div>
        <div class="p-field">
          <label style="display: block">Número</label>
          <p-inputtext
            v-model="data.enderecoNumero"
            required
            style="width: 100px"
          />
        </div>
        <div class="p-field">
          <label>Complemento</label>
          <p-inputtext v-model="data.complemento" />
        </div>
        <div class="p-field">
          <label>Referência</label>
          <p-inputtext v-model="data.referencia" />
        </div>
        <div class="p-field">
          <label>Bairro</label>
          <p-inputtext v-model="data.bairro" required />
        </div>
        <div class="p-field">
          <label>Cidade</label>
          <p-inputtext
            v-model="data.cidade"
            required
            :disabled="cep.encontrou.value"
          />
        </div>
        <div class="p-field">
          <label>UF</label>
          <p-dropdown
            v-model="data.uf"
            required
            :options="ufs"
            optionLabel="nome"
            optionValue="sigla"
            :disabled="cep.encontrou.value"
            :class="{ 'p-invalid': !data.uf }"
          />
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-fluid">
        <div class="p-field">
          <label>Contrato OK:</label>
          <p-inputswitch v-model="data.contratoAceito" />
        </div>
        <div class="p-field">
          <label>Tipo de Regime:</label>
          <p-dropdown
            v-model="data.regimeTributario"
            required
            :options="[
              { value: 'mei', label: 'MEI' },
              { value: 'simples', label: 'SIMPLES' },
              { value: 'lucro_presumido', label: 'LUCRO PRESUMIDO' },
              { value: 'lucro_real', label: 'LUCRO REAL' },
            ]"
            optionLabel="label"
            optionValue="value"
          />
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-fluid">
        <div class="p-field">
          <label>Observações Internas:</label>
          <p-textarea
            style="width: 100%; height: 100px; color: red"
            v-model="data.observacoes"
          />
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-fluid">
        <div class="p-field">
          <label>Detalhes (forma e horário de atendimento):</label>
          <p-textarea
            required
            style="width: 100%; height: 100px"
            v-model="data.detalhes"
          />
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-fluid">
        <div class="p-field">
          <label>Região Atuação:</label>
          <p-textarea
            required
            style="width: 100%; height: 100px"
            v-model="data.regiaoAtuacao"
          />
        </div>
      </div>

      <div class="p-col-12 p-d-flex p-flex-row-reverse p-jc-start">
        <p-button
          label="Salvar"
          :icon="`pi pi-save${saving || loading ? ' p-spin' : ''}`"
          :disabled="saving || loading"
          type="submit"
        />
        <p-button
          label="Cancelar"
          class="p-button-secondary p-mr-2"
          @click="close"
        />
      </div>
    </form>
  </p-dialog>
</template>

<script lang="ts">
import {
  getInstance,
  PontoApoio,
  initialPontoApoio,
} from "@/services/PontoApoioService";
import { defineComponent, PropType, watch } from "vue";
import { useCadastro } from "@/composables/crud";
import { useBuscaCep } from "@/composables/cep";
import { ufs } from "@/utils/endereco";
import Responsaveis from "./Responsaveis.vue";

export default defineComponent({
  components: { Responsaveis },
  emits: ["update:visible", "cadastrar"],
  props: {
    id: [Number, String] as PropType<number>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { data, reset, salvar, loading, saving, load } =
      useCadastro<PontoApoio>(getInstance(), initialPontoApoio);

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );
    function close() {
      if (!loading.value) {
        emit("update:visible", false);
      }
    }

    const cep = useBuscaCep((resp) => {
      data.logradouro = resp.logradouro;
      data.complemento = resp.complemento;
      data.bairro = resp.bairro;
      data.cidade = resp.localidade;
      data.uf = resp.uf;
    });

    return {
      cep,
      ufs,
      saving,
      loading,
      data,
      reset,

      close,
      async salvar() {
        if (await salvar(props.id)) {
          emit("cadastrar");
          close();
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && loading.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>