
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { getInstance, PontoApoio } from "@/services/PontoApoioService";
import Cadastro from "./Cadastro.vue";
import { useConsulta, useExcluir } from "@/composables/crud";
import { formatDate } from "@/utils/format";
import { useShowUser } from "@/components/UserView.vue";
import Operacoes from "./Operacoes.vue";

interface Operacoes {
  visible: boolean;
  pa: PontoApoio;
  show: (pa: PontoApoio) => void;
}

function useOperacoes() {
  const operacoes = reactive<Operacoes>({
    visible: false,
    pa: null!,
    show(pa: PontoApoio) {
      this.visible = true;
      this.pa = pa;
    },
  });
  watch(
    () => operacoes.visible,
    (v) => {
      if (!v) {
        operacoes.pa = null!;
      }
    }
  );
  return operacoes;
}

export default defineComponent({
  components: { Cadastro, Operacoes },
  setup() {
    const userView = useShowUser();
    const { data, loading, search, paginatorInfo, consultar } =
      useConsulta<PontoApoio>(getInstance());
    const { confirmarExcluir } = useExcluir(getInstance());
    const dialogCadastro = ref(false);
    const selecionado = ref<PontoApoio>();

    search.sortField = "e.id";
    search.sortOrder = "ASC";

    watch(dialogCadastro, (v) => !v && (selecionado.value = undefined));

    onMounted(() => consultar());

    const operacoes = useOperacoes();

    return {
      operacoes,
      userView,
      formatDate,
      dialogCadastro,
      data,
      selecionado,
      paginatorInfo,

      loading,
      search,
      consultar,
      onPage(event: any) {
        search.first = event.rows;
        search.page = event.page + 1;
      },
      onSort(event: any) {
        search.sortField = event.sortField;
        search.sortOrder = event.sortOrder > 0 ? "ASC" : "DESC";
      },

      editar(p: PontoApoio) {
        selecionado.value = p;
        dialogCadastro.value = true;
      },

      async excluir(p: PontoApoio) {
        (await confirmarExcluir(p.id)) && consultar();
      },

      operacaoUpdated() {
        consultar();
      },
    };
  },
});
